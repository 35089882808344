<template>
  <el-table
    :data="tableData"
    :span-method="objectSpanMethod"
    border
    style="width: 100%; margin-top: 20px"
  >
    <el-table-column
      prop="productName"
      label="功能区"
      width="110"
    />
    <el-table-column
      label="测评项目"
    >
      <div slot-scope="scope">
        <div
          v-if="scope.row.projectName"
          class="item"
        >
          <div class="label">
            {{ scope.row.projectName }}：
          </div>
          <div class="value">
            {{ scope.row.productItemName }}
          </div>
        </div>
      </div>
    </el-table-column>
    <el-table-column
      prop="productItemSelect"
      label="测评通过等级"
      align="center"
      width="110"
    >
      <span
        slot-scope="scope"
        :style="{color: /d|D/.test(scope.row.productItemSelect)?'red': ''}"
      >
        {{ scope.row.productItemSelect }}
      </span>
    </el-table-column>
    <el-table-column
      prop="totalScore"
      label="得分"
      align="center"
      width="60"
    />
  </el-table>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    tableLen() {
      return this.list.length;
    },
    tableData() {
      const tmpArr = [];
      const sorts = this.list.sort((a, b) => a.productId - b.productId);
      const list = sorts.map((item, index, arr) => {
        const tmp = { ...item };
        if (!tmpArr.includes(tmp.productName)) {
          const len = arr.filter((sub) => sub.productName === tmp.productName).length;
          tmp.rowspan = len;
          tmpArr.push(tmp.productName);
        }
        return tmp;
      });
      return list;
    }
  },
  methods: {
    objectSpanMethod({
      rowIndex, columnIndex, row
    }) {
      if (row.productName?.includes('综合能力')) {
        if (columnIndex === 1) {
          return {
            rowspan: 1,
            colspan: 2
          };
        } if (columnIndex === 2) {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
        return {
          rowspan: 1,
          colspan: 1
        };
      }
      // 行合并
      if (columnIndex === 0 || columnIndex === 3) {
        return {
          rowspan: row.rowspan,
          colspan: 1
        };
      }
      return {
        rowspan: 1,
        colspan: 1
      };
    }
  }
};
</script>

<style lang="less" scoped>
.item{
  display: flex;
  .label{
    font-weight: 500;
  }
  .value{
    flex: 1;
  }
}
</style>
